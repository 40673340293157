import React, { useState } from "react"
import { Formik, Field } from "formik"
import {
  StyledWrapper,
  StyledField,
  ErrorDiv,
  CheckboxGroup,
  CheckboxField,
  StyledForm,
  StyledSubmit,
} from "./InquiryForm.style"
import axios from "axios"
import * as Yup from "yup"
import Reaptcha from "reaptcha"

const InquirySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nazwa placówki jest zbyt krótka.")
    .required("Prosimy podać nazwę placówki."),
  email: Yup.string()
    .email("Email jest niepoprawnego formatu.")
    .required("Prosimy podać adres email."),
  date: Yup.string().required("Prosimy wybrać termin prezentacji."),
  phone: Yup.string().required("Telefon kontaktowy jest wymagany."),
})

const InquiryForm = () => {
  const [formState, setFormState] = useState({
    buttonEnabled: false,
    status: null,
  })

  const handleOnSubmit = (e, { resetForm }) => {
    var bodyFormData = new FormData()

    if (e.modernizacja_apteki == "") e.modernizacja_apteki = "Nie"

    if (e.system_bezpieczenstwa == "") e.system_bezpieczenstwa = "Nie"

    bodyFormData.append("name", e.name)
    bodyFormData.append("phone", e.phone)
    bodyFormData.append("email", e.email)
    bodyFormData.append("date", e.date)
    bodyFormData.append("zagadnienia", e.zagadnienia)
    bodyFormData.append("modernizacja_apteki", e.modernizacja_apteki)
    bodyFormData.append("system_bezpieczenstwa", e.system_bezpieczenstwa)

    axios({
      method: "post",
      url: "https://infofinder.pl/mail-offer.php",
      data: bodyFormData,
    })

    alert(
      "Dziękujemy za przesłanie formularza. Skontaktujemy się tak szybko jak to możliwe."
    )

    resetForm();
  }

  const Checkbox = props => {
    return (
      <Field name={props.name}>
        {({ field, form }) => (
          <label>
            <input
              type="checkbox"
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    value => value !== props.value
                  )
                  form.setFieldValue(props.name, nextValue)
                } else {
                  const nextValue = field.value.concat(props.value)
                  form.setFieldValue(props.name, nextValue)
                }
              }}
            />
          </label>
        )}
      </Field>
    )
  }

  const onVerify = event => {
    setFormState({
      buttonEnabled: true,
    })
  }

  const onExpire = event => {
    setFormState({
      buttonEnabled: false,
    })
  }

  return (
    <StyledWrapper>
      <h1>_</h1>
      <Formik
        initialValues={{
          name: "",
          phone: "",
          email: "",
          date: "",
          zagadnienia: [],
          modernizacja_apteki: [],
          system_bezpieczenstwa: [],
        }}
        validationSchema={InquirySchema}
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched }) => (
          <div>
            <StyledForm>
              <h2 htmlFor="firstName">
                Które zagadnienia lub problemy są dla Państwa najistotniejsze?
                (wymagane)
              </h2>
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox
                    name="zagadnienia"
                    value="Zmiany w przepisach prawa farmaceutycznego w zakresie bezpieczeństwa leków"
                  />
                  <span>
                    Zmiany w przepisach prawa farmaceutycznego w zakresie
                    bezpieczeństwa leków.
                  </span>
                </CheckboxField>
                <CheckboxField>
                  <Checkbox
                    name="zagadnienia"
                    value="System autentyfikacji leków od 9 lutego 2019 r."
                  />
                  <span>System autentyfikacji leków od 9 lutego 2019 r.</span>
                </CheckboxField>
                <CheckboxField>
                  <Checkbox
                    name="zagadnienia"
                    value="Dobowe raporty do ZSMOPL"
                  />
                  <span>Dobowe raporty do ZSMOPL.</span>
                </CheckboxField>
                <CheckboxField>
                  <Checkbox
                    name="zagadnienia"
                    value="Paszportyzacja i kodowanie leków."
                  />
                  <span>Paszportyzacja i kodowanie leków.</span>
                </CheckboxField>
                <CheckboxField>
                  <Checkbox
                    name="zagadnienia"
                    value="Narzędzia informatyczne do skutecznego nadzoru farmaceutycznego"
                  />
                  <span>
                    Narzędzia informatyczne do skutecznego nadzoru
                    farmaceutycznego.
                  </span>
                </CheckboxField>
              </CheckboxGroup>
            </StyledForm>
            <StyledForm>
              <h2 htmlFor="firstName">
                Czy planowana jest w Państwa szpitalu modernizacja Apteki
                Szpitalnej lub całej gospodarki lekami?
              </h2>
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox name="modernizacja_apteki" value="Tak" />
                  <span>Tak</span>
                </CheckboxField>
              </CheckboxGroup>
            </StyledForm>
            <StyledForm>
              <h2 htmlFor="firstName">
                Czy są Państwo zainteresowani prezentacją systemu bezpieczeństwa
                gospodarki lekami w Waszym szpitalu?
              </h2>
              <CheckboxGroup>
                <CheckboxField>
                  <Checkbox name="system_bezpieczenstwa" value="Tak" />
                  <span>Tak</span>
                </CheckboxField>
              </CheckboxGroup>
            </StyledForm>
            <StyledForm>
              <h2>Dane Kontaktowe</h2>

              <label htmlFor="name">Nazwa placówki</label>
              <StyledField name="name" placeholder="" />
              {errors.name && touched.name ? (
                <ErrorDiv>{errors.name}</ErrorDiv>
              ) : null}

              <label htmlFor="phone">Telefon kontaktowy (wymagane)</label>
              <StyledField name="phone" placeholder="" />
              {errors.phone && touched.phone ? (
                <ErrorDiv>{errors.phone}</ErrorDiv>
              ) : null}

              <label htmlFor="email">Adres e-mail (wymagane)</label>
              <StyledField name="email" placeholder="" type="email" />
              {errors.email && touched.email ? (
                <ErrorDiv>{errors.email}</ErrorDiv>
              ) : null}

              <label htmlFor="date">
                Proponowany termin prezentacji systemu HLS (wymagane)
              </label>
              <StyledField name="date" placeholder="dd.mm.yyyy" type="date" />
              {errors.date && touched.date ? (
                <ErrorDiv>{errors.date}</ErrorDiv>
              ) : null}

              <Reaptcha
                sitekey="6LfwMtAUAAAAAG4dF3Gf9LiLqIHyJeW3aATPt033"
                onVerify={onVerify}
                onExpire={onExpire}
              />
              <StyledSubmit type="submit" disabled={!formState.buttonEnabled}>
                WYŚLIJ
              </StyledSubmit>
            </StyledForm>
          </div>
        )}
      </Formik>
    </StyledWrapper>
  )
}

export default InquiryForm

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";
import InquiryForm from "../components/Inquiry/Sections/InquiryForm";

const InquiryPage = () => (
  <div style={{backgroundColor: "#f2e3fc"}}>
    <Layout>
      <SEO title="Zapytanie Ofertowe" />
      <Hero title="Zapytanie Ofertowe" />
      <InquiryForm />
    </Layout>
  </div>
)

export default InquiryPage

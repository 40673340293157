import styled from 'styled-components';
import { Field, Form } from 'formik';

export const StyledWrapper = styled.div`
  background-color: #f2e3fc;
`;

export const StyledForm = styled(Form)`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 80px;

    h2 {
      margin-bottom: 40px;
      font-weight: normal;
      font-size: 1rem;
    }

    label {
      font-size: 0.7rem;
    }
`;

export const StyledField = styled(Field)`
    margin-bottom: 20px;
    border: 0;
    border-radius: 5px;
    padding: 10px;
`;

export const StyledSubmit = styled.button`
    width: fit-content;
    padding: 8px 15px;
    margin: 0 auto;
    letter-spacing: 2px;
    margin-top: 40px;
    background-color: white;
    font-weight: bold;
    font-size: 12px;
    border: 1px solid black;
    cursor: pointer;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
`;

export const CheckboxField = styled.div`
  display: flex;
  flex-direction: row;

  span {
    margin-left: 5px;
    font-size: 0.8rem;
  }
`;

export const ErrorDiv = styled.div`
  color: red;
  font-weight: bold;
  font-size: 0.8rem;
  margin-top: -10px;
  margin-bottom: 10px;
`;